<template>

  <div>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="10"
      >
        <v-form @submit="createMember()">
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Žiadosť o členstvo v Našom Hnutí</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text v-if="user.member_approved == false">
              Tvoja žiadosť bola daná na schválenie administrátorom. Po schválení budeš viditeľný v zozname členov.
            </v-card-text>
            <v-card-text v-else-if="user.member_id">
              V prípade aktualizácie žiadosti bude tvoja žiadosť o členstvo daná na
              opätovné schválenie administrátorom.
            </v-card-text>
            <v-card-text>
              <p>
                <span v-if="user.gender == 'MAN'">Vážený žiadateľ</span><span v-else>Vážená žiadateľka</span> {{ user.first_name }} {{user.last_name}} o členstvo v Našom Hnutí, predpokladáme, že si si vyplnil svoj profil správne a pravdivo lebo údaje z tvojho profilu sa automaticky natiahnu všade na tejto stránke v súvislosti s tvojou osobou. Preto si ich skontroluj a uvádzaj tam všetko správne a pravdivo. Nepravdivo vyplnený formulár o tvojich údajoch môže byť dôvodom na zrušenie tvojho profilu a tým všetkých aktivít a práv na tejto stránke.
              </p>

              <p>Ako budúci člen Nášho Hnutia budeš mať užší vzťah s Našim Hnutím a preto potrebujeme aj viac identifikačných údajov o tvojej osobe. Preto prosíme aby si potvrdil aj nasledovné údaje, ktoré sú povinné pre každého člena Nášho Hnutia. Údaje budú zobrazené verejnosti nasledovne: meno bude nastavené ako viditeľné, priezvisko si môžeš zvoliť sám, či chceš aby bolo viditeľné a mesto a okres bude nastavené ako viditeľné.</p>

            </v-card-text>
            <v-card-text v-if="user.verified_clen">
              <v-row>
                <v-col
                  md="3"
                  sm="8"
                  cols="6"
                >
                  <v-text-field
                    v-model="user.first_name"
                    disabled
                    label="Meno"
                    name="first_name"
                    prepend-icon="mdi-account"
                    type="text"
                  />
                </v-col>
                <v-col
                  md="3"
                  sm="4"
                  cols="6"
                >
                  <v-switch
                    v-model="form.first_name_public"
                    disabled
                    label="Zverejnené"
                  ></v-switch>
                </v-col>
                <v-col
                  md="3"
                  sm="8"
                  cols="6"
                >
                  <v-text-field
                    v-model="user.last_name"
                    disabled
                    label="Priezvisko"
                    name="last_name"
                    prepend-icon="mdi-account"
                    type="text"
                  />
                </v-col>
                <v-col
                  md="3"
                  sm="4"
                  cols="6"
                >
                  <v-switch
                    v-model="form.last_name_public"
                    label="Zverejnené"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  md="3"
                  sm="8"
                  cols="6"
                >
                  <v-text-field
                    v-model="user.address_town"
                    disabled
                    label="Mesto/Obec"
                    name="address_town"
                    prepend-icon="mdi-map-marker"
                    type="text"
                  />
                </v-col>
                <v-col
                  md="3"
                  sm="4"
                  cols="6"
                >
                  <v-switch
                    v-model="form.address_town_public"
                    disabled
                    label="Zverejnené"
                  ></v-switch>
                </v-col>

                <v-col
                  md="3"
                  sm="8"
                >
                  <v-text-field
                    v-model="user.address_zip_code"
                    disabled
                    label="PSČ"
                    name="address_zip_code"
                    prepend-icon="mdi-map-marker"
                    type="text"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  md="3"
                  sm="8"
                  cols="6"
                >
                  <v-text-field
                    v-model="user.address_okres"
                    disabled
                    label="Okres"
                    name="address_okres"
                    prepend-icon="mdi-map-marker"
                    type="text"
                  />
                </v-col>
                <v-col
                  md="3"
                  sm="4"
                  cols="6"
                >
                  <v-switch
                    v-model="form.address_okres_public"
                    disabled
                    label="Zverejnené"
                  ></v-switch>
                </v-col>

                <v-col
                  md="3"
                  sm="8"
                  cols="6"
                >
                  <v-text-field
                    v-model="user.address_kraj"
                    disabled
                    label="Kraj"
                    name="address_kraj"
                    prepend-icon="mdi-map-marker"
                    type="text"
                  />
                </v-col>
                <v-col
                  md="3"
                  sm="4"
                  cols="6"
                >
                  <v-switch
                    v-model="form.address_kraj_public"
                    disabled
                    label="Zverejnené"
                  ></v-switch>
                </v-col>

              </v-row>
              <v-row>
                <v-col
                  md="3"
                  sm="8"
                >
                  <v-text-field
                    v-model="user.address_country"
                    disabled
                    label="Štát"
                    name="address_country"
                    prepend-icon="mdi-flag"
                    type="text"
                  />
                </v-col>

                <v-col
                  md="3"
                  sm="4"
                >
                  <v-switch
                    v-model="form.address_country_public"
                    disabled
                    label="Zverejnené"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  md="3"
                  sm="8"
                >
                  <v-select
                    v-model="form.payment"
                    label="Platba"
                    name="payment"
                    prepend-icon="mdi-cash"
                    :items="selects"
                  />
                </v-col>
                <v-col
                  md="6"
                  sm="4"
                >
                  <v-checkbox
                    v-if="form.payment == 'MONTHLY'"
                    v-model="agreement_payment"
                    :label="popisMesiac"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-else
                    v-model="agreement_payment"
                    :label="popisRok"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <p>
                    Platobný príkaz: IBAN: SK4983300000004111441114, Variabilný symbol: {{user.id}}, Suma: <span v-if="form.payment == 'MONTHLY'">{{getSettings.hladina_clen}} </span> <span v-else>{{Math.round(getSettings.hladina_clen *12)}} </span>€
                  </p>
                </v-col>
              </v-row>

              <v-checkbox
                v-model="agreement_dohoda"
                label="Súhlasím s dohodou o členstve v Našom Hnutí a o podmienkach pre člena Nášho Hnutia"
              >
              </v-checkbox>

              <v-checkbox
                v-model="agreement_18"
                label="Mám viac ako ako 18 rokov"
              >
              </v-checkbox>

              <v-checkbox
                v-model="agreement_stanovy"
                label="Súhlasím so Stanovami Nášho Hnutia"
              >
              </v-checkbox>

              <v-checkbox
                v-model="agreement"
                label="Potvrdzujem, že údaje som vyplnil správne a pravdivo"
              >
              </v-checkbox>

              <g-d-p-r-consent v-model="agreement_gdpr"></g-d-p-r-consent>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!(agreement && agreement_payment && agreement_18 && agreement_stanovy && agreement_dohoda && agreement_gdpr)"
                  color="primary"
                  @click="createMember()"
                >
                  <span v-if="user.verified_clen"> Odoslať žiadosť </span>
                </v-btn>
              </v-card-actions>
            </v-card-text>
            <v-card-text v-else-if="!user.verified_clen">
              Požiadať o členstvo môžete až po splnení podmienok.
            </v-card-text>

            <v-card-text>
              <p class="text-h6"> Tvoj status členstva je aktuálne: <span v-if="!form.status"> Žiadny </span><span v-else-if="form.status == 'WAITING'">Čakajúci</span> <span v-else-if="form.status == 'ACTIVE'">Aktívny</span> <span v-else-if="form.status == 'PAUSED'">Pozastavený</span><span v-else-if="form.status == 'CANCELED'">Zrušený</span> <span v-else> Žiadny </span></p>

              <p> Členstvo bude aktívne automaticky po pripísaní sumy (na náš účet v banke), ktorú si vyberieš a to buď na 30 dní alebo na 365 dní. Túto informáciu získa systém z bankového účtu a preto dbaj na správne zadané identifikačné údaje o platbe. V prípade, že nebude zaplatený členský príspevok bude tvoje členstvo najskôr pozastavené a potom zrušené. </p>

              <p v-if="user.member_id">Gratulujeme {{ user.first_name }} {{user.last_name}}, formulár Žiadosť o členstvo v Našom Hnutí si riadne vyplnil a už sa čaká len na tvoju úhradu členského poplatku pripísanú na náš účet. Po jej automatizovanom spracovaní si sa stal riadnym členom Nášho Hnutia.

                Ďakujeme.

                Tím NH.
              </p>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar_saved"
      color="success"
      :timeout="-1"
    >
      <p> Gratulujeme {{ user.first_name}} {{ user.last_name}}, tvoja žiadosť bola úspešne odoslaná. Teraz sa čaká na potvrdenie členstva administrátorom a pripísanie platby za členský príspevok na účet v banke.</p>
      <p> Platobný príkaz: IBAN: SK4983300000004111441114, Variabilný symbol: {{user.id}}, Suma: <span v-if="form.payment == 'MONTHLY'">{{getSettings.hladina_clen}} </span> <span v-else>{{Math.round(getSettings.hladina_clen *12)}} </span>€</p>

      <v-btn
        text
        @click="snackbar_saved = false"
      >
        Zavrieť
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbar_error"
      color="error"
      :timeout="10000"
    >
      Chyba
    </v-snackbar>

  </div>
</template>

<script>
import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";
import GDPRConsent from '../components/GDPRConsent.vue';

const apiService = new APIService();

export default {
  props: {
    source: String,
  },

  components: {
    GDPRConsent
  },

  data() {
    return {
      loading: false,
      drawer: null,
      agreement: false,
      agreement_payment: false,
      agreement_18: false,
      agreement_stanovy: false,
      agreement_dohoda: false,
      agreement_gdpr: false,


      snackbar_saved: false,
      snackbar_error: false,

      photo_file: null,
      video_file: null,

      user: {},
      form: {
        first_name_public: true,
        last_name_public: true,
        address_town_public: true,
        address_okres_public: true,
        address_kraj_public: true,
        address_country_public: true,
        address_zip_code_public: true,
        payment: "MONTHLY"
      },

      selects: [
        { text: "Ročne", value: "YEARLY" },
        { text: "Mesačne", value: "MONTHLY" },
      ]
    };
  },

  computed: {
    popisMesiac: function () {
      return `Členský poplatok vo výške ${this.getSettings.hladina_clen} € sa zaväzujem platiť každých 30 dní`
    },

    popisRok: function () {
      return `Členský poplatok vo výške ${Math.round(this.getSettings.hladina_clen * 12)} € sa zaväzujem platiť každých 365 dní`
    },
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("settings", ["getSettings"]),
  },

  mounted: function () {
    this.getMe();
  },

  methods: {
    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
        if (this.user.member_id != null) {
          this.agreement = true;
          this.agreement_payment = true;
          this.agreement_18 = true;
          this.agreement_stanovy = true;
          this.agreement_dohoda = true;

          apiService.getMember(this.user.member_id).then((response) => {
            this.form = response;
          });
        }
        this.loading = false;
      });
    },

    createMember() {
      if (!this.user.member_id) {
        confirm("Naozaj chcete odoslať žiadosť?") &&
          apiService.createMember(this.form).then(() => {
            this.snackbar_saved = true;
            this.getMe();
          });
      } else {
        this.form.user = null;
        confirm("Naozaj chcete odoslať žiadosť?") &&
          apiService.updateMember(this.form).then(() => {
            this.snackbar_saved = true;
            this.getMe();
          });
      }
    },

  },
};
</script>